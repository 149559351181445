<template>
<div class="examin" v-loading="pageLoading">
  <div class="progress">
      <div class="progressText">
         <p>{{rate}}%</p>
         完成率
      </div>
      <el-progress type="circle" 
      :percentage="rate"
      :width="176"
      :stroke-width="16"
      :show-text="false"
      color="#417ae6"
      define-back-color="#ff0000"
    ></el-progress>
  </div>
  <div class="des">
    <p><b>{{ info.doing }}</b>已答题</p>
    <p><b>{{ info.error }}</b>错题</p>
    <p><b>{{parseInt(info ? info.percentage : 0)}}%</b>正确率</p>
  </div>
  <div class="btn">
    <p @click="toExamin()">继续答题</p>
    <p @click="toDetail()">查看答题详情</p>
  </div>
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      info:{},
      rate:0,
      id:'',
      isPass:true,
      isPass2:true,
      passImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/pass.png',
      noPassImg:'https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/noPass.png',
      pageLoading: false,
      examinResult:1,//1当前考试成绩，2最终考试成绩
    };
  },
  async mounted() {
    this.id = this.$route.query.id
    this.getData()
    // this.isPass = this.$route.query.isPass==1 ? true : false
   
  },
  destroyed() {
   
  },
  computed: {
   
  },
  methods: {
    async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/test/imitate",
        data: {
          test_id: this.$route.query.test_id,
          uid: this.$route.query.uid,
          report_id:  this.$route.query.report_id,
        },
      });
      this.pageLoading = false;

      if (res.code == 0) {

        this.info = res.data
        this.rate = parseFloat(this.info.doing / (this.info.doing + this.info.no_doing) * 100).toFixed(2)
      } else {
        this.$toast(res.msg);
      }
    },
    toExamin(){
      this.$router.push({path:"./examinList?id="+this.$route.query.test_id+'&type=0'})
    },
    toDetail(){
      this.$router.replace({
          path:'./examinResultDetail',
          query:{
            test_id: this.$route.query.test_id,
            uid: this.$route.query.uid,
            report_id:  this.$route.query.report_id,
            isReview:true
          }
        })
    },
   
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-progress path:first-child {
    stroke: #dfeaff;
  }

.examin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  width:1200px;
  background: #f6f9fc;
  border-radius: 8px;
  margin: 20px auto;
  text-align: center;
  padding: 50px 0 60px;
  .progress{
    position: relative;
    margin-bottom: 30px;
    .progressText{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      font-size: 14px;
      color: #666;
      p{
        font-size: 40px;
        color: #333;
        font-weight: bold;
      }
    }
  }
  .des{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
      margin: 0 45px;
      font-size: 14px;
      color: #666;
      text-align: center;
      b{
        display: block;
        font-size: 30px;
        margin-bottom: 5px;
        color: #333;
      }
    }
  }
  .btn{
    display: flex;
    justify-content: center;
    margin: 40px auto 0;
    p{
      width: 170px;
      height: 40px;
      border-radius: 4px;
      text-align: center;
      line-height: 40px;
      font-size: 16px;
      border: 1px solid #2e66d0;
      cursor: pointer;
      margin: 0 16px;
      &:first-of-type{
        background: #2e66d0;
        color: #fff;
      }
      &:nth-of-type(2){
        background: #fff;
        color: #2e66d0;
      }
    }
    
  }
}
</style>

<style lang="scss">
</style>
